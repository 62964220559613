/**
 * クラス名：ホーム画面
 * 説明：ログイン後ホーム画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Layout, Image, Button, Row, Col, Space, Input, Spin, Modal } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { Table as RTable } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const { Content, Sider } = Layout;

const MainLayout = React.forwardRef((props, ref) => {
  return <></>;
});

export default MainLayout;
