/**
 * クラス名：ログイン画面
 * 説明：システムログイン画面である。
 * 作成者：ナンス
 * 作成日：2024/11/14
 * バージョン：1.0
 */
import { Form, Input, Button, Image, Checkbox, Alert, Modal } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import logo from '../assets/images/logo.ico';
import { WithRouter } from '../components/WithRouter';
import { StatusCodes } from 'http-status-codes';

import '../assets/styles/common.css';

const Login = forwardRef((props, ref) => {
  const formRef = React.useRef(null);
  let params = new URLSearchParams(window.location.search);
  const [errorMessage, setErrorMessage] = useState(params.has('ErrorMessage') ? params.get('ErrorMessage') : '');
  const [loading, setLoading] = useState(false);
  const [isAliveToken, setIsAliveToken] = useState(false);

  useEffect(() => {
    // isActiveToken(); // ログインのトークン有効チェック
  }, []);

  // ログインボタンの押下
  const handleSubmit = async (values) => {
    let loginId = values?.loginId;
    let password = values?.password;
    let remember = values?.rememberMe;
    setLoading(true);
    // await isOtherLogin(loginId, password, remember);
    // await login(loginId, password, remember);
    setLoading(false);
  };

  // ログイン処理
  const isOtherLogin = async (loginId, password, remember) => {
    // 必須チェック
    if (!loginId || !password) {
      setErrorMessage(ErrorMessage().E005);
      return;
    }
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Auth/IsOtherLogin';
      const otherParam = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          loginId: loginId,
          password: password,
          rememberMe: remember,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then(async (data) => {
          if (data && data?.statusCode === StatusCodes.OK) {
            // 正常の場合、
            await login(loginId, password, remember);
          } else if (
            data &&
            (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
          ) {
            Modal.confirm({
              width: 465,
              className: 'modal-error',
              title: '確認',
              content: (
                <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
                  {ErrorMessage()[data?.messageCode]}
                </p>
              ),
              okText: 'ログイン',
              cancelText: 'キャンセル',
              onOk: async () => {
                await login(loginId, password, remember);
              },
            });
          } else if (data && data?.statusCode !== StatusCodes.OK) {
            // 異常の場合、
            setErrorMessage(ErrorMessage()[data?.messageCode]);
          }
        })
        .catch((error) => {
          // 異常の場合、
          setErrorMessage(ErrorMessage().E010);
        });
    } catch (error) {
      // 異常の場合、
      setErrorMessage(ErrorMessage().E003);
      return error;
    }
  };

  // ログイン処理
  const login = async (loginId, password, remember) => {
    // 必須チェック
    if (!loginId || !password) {
      setErrorMessage(ErrorMessage().E005);
      return;
    }
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Auth/Login';
      const otherParam = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          loginId: loginId,
          password: password,
          rememberMe: remember,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then(async (data) => {
          if (data && data?.statusCode === StatusCodes.OK) {
            // 正常の場合、
            let token = data.updateToken;
            // セクションにトークン情報を保持する
            updateAccessToken(token);
            // ログインユーザー情報を取得する
            let result = await getCurrentUserInfo();
            if (result === '') {
              await changeRoute();
            } else {
              // 異常の場合、
              setErrorMessage(result);
            }
          } else if (
            data &&
            (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
          ) {
            setErrorMessage(ErrorMessage()[data?.messageCode]);
          } else if (data && data?.statusCode !== StatusCodes.OK) {
            // 異常の場合、
            setErrorMessage(ErrorMessage()[data?.messageCode]);
          }
        })
        .catch((error) => {
          // 異常の場合、
          setErrorMessage(ErrorMessage().E010);
        });
    } catch (error) {
      // 異常の場合、
      setErrorMessage(ErrorMessage().E003);
      return error;
    }
  };

  // 入力項目に値を設定
  const setFormValue = (name, value) => {
    formRef.current?.setFieldValue(name, value);
  };

  // Routeを変更する
  const changeRoute = async () => {
    let userSettingInfo = await getExistUserSetting();
    let gotoRoute = '/home/:parentList';
    if (userSettingInfo !== undefined && userSettingInfo?.length !== 0) {
      switch (userSettingInfo?.[0]?.users?.initShowDisp) {
        case 0:
          gotoRoute = '/home/:parentList';
          break;
        case 1:
          gotoRoute = '/home/:estimate.parent';
          break;
        case 2:
          gotoRoute = '/home/:estimate.iq3';
          break;
        default:
          gotoRoute = '/home/:parentList';
          break;
      }
    }
    //係数・追加項目一覧をセッションにセット。
    props.navigate(gotoRoute, { state: { fromLogin: true } });
  };

  return (
    <div
      style={{
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          marginTop: '70px',
          display: 'grid',
        }}
      >
        <label style={{ fontSize: '20px', fontWeight: '600', color: '#333333' }}>1分で見積もり＆発注できる</label>
        <label style={{ fontSize: '20px', fontWeight: '600', color: '#333333', marginTop: 5 }}>RealGoku</label>
        <div
          style={{
            display: 'flex',
            marginTop: '13px',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              backgroundColor: '#21252938',
              borderRadius: 3,
              padding: 10,
              width: '87%',
            }}
          >
            <Image preview={false} width={40} height={40} src={logo}></Image>
          </div>
        </div>
        <Form
          name="Login"
          onFinish={handleSubmit}
          ref={formRef}
          layout="vertical"
          style={{
            maxWidth: '500px',
            height: '100%',
            marginTop: 40,
            boxShadow: '0px 0px 10px 0px #00000040',
            border: '1px solid #b5b2ae29',
            borderRadius: 5,
            padding: 25,
          }}
          initialValues={{
            loginId: 'user@zero-four.co.jp',
            password: '1234',
            rememberMe: false,
          }}
        >
          {/* 会員ID */}
          <Form.Item
            style={{ textAlign: 'left', marginBottom: 18 }}
            name="loginId"
            label={<label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>会員ID</label>}
          >
            <Input style={{ width: '300px', height: '32px' }} placeholder="user@zero-four.co.jp" />
          </Form.Item>
          {/* パスワード */}
          <Form.Item
            style={{ textAlign: 'left', marginBottom: 30 }}
            name="password"
            label={<label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>パスワード</label>}
          >
            <Input style={{ width: '300px', height: '32px' }} type="password" placeholder="xxxxxx" />
          </Form.Item>
          {/* ログインボタン */}
          <Form.Item style={{ textAlign: 'left', marginBottom: 4 }}>
            <Button
              key="login"
              style={{
                backgroundColor: '#212529',
                color: '#ffffff',
                width: '300px',
                height: '40px',
                fontWeight: '600',
                borderColor: '#808080',
                borderWidth: '1px',
                borderRadius: 3,
              }}
              type="primary"
              htmlType="submit2"
              loading={loading | { delay: 500 }}
            >
              ログイン
            </Button>
          </Form.Item>
          {/* 会員IDをお忘れの場合 */}
          <Form.Item name="companyIdForget" style={{ textAlign: 'left', marginBottom: -10 }}>
            <a href="#">
              <label style={{ color: '#039', fontSize: '13px', cursor: 'pointer' }}>会員IDをお忘れの場合</label>
            </a>
          </Form.Item>
          <Form.Item name="passwrodForget" style={{ textAlign: 'left', marginBottom: 18 }}>
            <a href="#">
              <label style={{ color: '#039', fontSize: '13px', cursor: 'pointer' }}>パスワードをお忘れの場合</label>
            </a>
          </Form.Item>
          {/* ログインボタン */}
          <Form.Item>
            <Button
              key="login"
              style={{
                backgroundColor: '#212529a8',
                color: '#ffffff',
                width: '300px',
                height: '40px',
                fontWeight: '600',
                borderColor: '#808080',
                borderWidth: '1px',
                borderRadius: 3,
              }}
              type="primary"
              htmlType="submit2"
              loading={loading | { delay: 500 }}
            >
              新規会員登録
            </Button>
          </Form.Item>
          {errorMessage != '' ? (
            <Form.Item name="errorMessage">
              <Alert
                style={{ width: 380, textAlign: 'center', whiteSpace: 'pre-line' }}
                message={errorMessage}
                type="error"
                showIcon
              />
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
      </div>
    </div>
  );
});

export default WithRouter(Login);
